//TODO : imptove interface like état de paiement activité
<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h5 style="color: blue; margin-bottom: 30px">
          {{ $t("paiement.paiementClasse") }}
        </h5>
        <label>{{ $t("paiement.listeClasses") }}</label>
        <a-select
          id="selectClass"
          show-search
          :placeholder="$t('paiement.listeClasses')"
          option-filter-prop="children"
          :filter-option="filterOption"
          style="width: 200px; margin-left: 15px"
          @change="
            (val) => {
              handleFilterChange('classe', val);
            }
          "
        >
          <a-select-option value="all">
            {{ $t("paiement.tousClasses") }}
          </a-select-option>
          <a-select-option
            v-for="classe in classes"
            :key="classe._id"
            :value="classe._id"
          >
            {{ classe.name }}
          </a-select-option>
        </a-select>
        <label style="margin-right: 15px; margin-left: 90px">{{
          $t("paiement.mois")
        }}</label>
        <a-select
          id="selectMonth"
          :placeholder="$t('paiement.selectionnerMois')"
          style="width: 200px; margin-left: 15px"
          @change="
            (val) => {
              handleFilterChange('month', val);
            }
          "
        >
          <a-select-option value="all">
            {{ $t("paiement.tousMois") }}
          </a-select-option>
          <a-select-option
            v-for="(m, index) in monthsArr"
            :key="index + 1"
            :value="index + 1"
          >
            {{ m }}
          </a-select-option>
        </a-select>
      </div>
      <div>
        <h5 style="color: blue; margin-top: 30px">
          {{ $t("inscription.inscription") }}
        </h5>
        <a-button type="file-pdf" @click="convert" style="float: right">
          <a-icon type="file-pdf" @click="convert" />
          {{ $t("paiement.imprimerTab") }} </a-button
        ><br /><br />
        <a-table
          @change="tableChanged"
          :loading="tableLoading"
          :pagination="true"
          :data-source="activeData"
          :columns="columns"
        >
          <template slot="expandedRowRender" slot-scope="record">
            <div style="margin: 0">
              <div
                v-for="(fee, key) in record.fees"
                :key="key"
                class="d-inline"
              >
                <a-tag :color="fee.isPayed ? 'green' : 'red'">
                  <b v-if="fee.isPayed"
                    >{{ formatFeeName(fee.feeName) }}
                    {{ $t("paiement.paye") }}</b
                  >
                  <b v-else
                    >{{ formatFeeName(fee.feeName) }}
                    {{ $t("paiement.impaye") }}</b
                  >
                </a-tag>
              </div>
            </div>
          </template>
          <template slot="mode" slot-scope="text">
            <a-tag color="#3498db" v-if="text == 'M'">
              Paiement mensuelle
            </a-tag>
            <a-tag color="#27ae60" v-else> Paiement par tranche </a-tag>
          </template>
          <template slot="status" slot-scope="text, record">
            <a-tag :color="record.status == 'payed' ? 'green' : 'red'">
              <b v-if="record.status == 'payed'"> {{ $t("paiement.paye") }}</b>
              <b v-else> {{ $t("paiement.impaye") }}</b>
            </a-tag>
          </template>
          <template slot="libelle" slot-scope="text, record">
            <editable-cell
              :text="text"
              @change="onCellChange(record.key, 'libelle', $event)"
            />
          </template>
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="$t('paiement.chercher') + ` ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("inscription.rechercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable */
//ANCHOR class/month filter to this view: DONE
import "jspdf-autotable";
import apiClient from "@/services/axios";
import { mapState } from "vuex";
import moment from "moment";
import { formatFeeName } from "@/utilities/settings";
import { pdfHeader } from "@/utilities/pdfUtils";

const getStatus = (fees) => {
  if (fees.find((f) => !f.isPayed)) return "impayed";
  else {
    return "payed";
  }
};
export default {
  computed: mapState(["settings"]),
  async created() {
    this.monthsArr = moment.months();
    this.tableLoading = true;

    apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classes = res.data));
    this.fetchPayments();
  },
  data() {
    return {
      monthsArr: [],
      filtredTable: [],
      tableLoading: false,
      activeData: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
      rowData: [],
      data1: null,
      classes: [],
      editable: false,
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      columns: [
        {
          title: this.$t("inscription.nom"),
          dataIndex: "student",
          key: "student",
          scopedSlots: {
            customRender: "student",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.student
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("menu.scolarite"),
          dataIndex: "name",
          key: "name",
          scopedSlots: {
            customRender: "name",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: this.$t("inscription.parent"),
          dataIndex: "parent",
          key: "parent",
          scopedSlots: {
            customRender: "parent",
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) =>
            record.parent
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "Mode de paiement",
          dataIndex: "mode",
          key: "mode",
          width: "150px",
          scopedSlots: {
            customRender: "mode",
          },
          filters: [
            { text: "Mensuelle", value: "M" },
            { text: "Par tranche", value: "T" },
          ],

          onFilter: (value, record) => record.mode.indexOf(value) === 0,
        },
        {
          title: "État global",
          dataIndex: "status",
          key: "status",
          filters: [
            {
              text: this.$t("paiement.paye"),
              value: "payed",
            },
            {
              text: this.$t("paiement.impaye"),
              value: "impayed",
            },
          ],
          scopedSlots: {
            customRender: "status",
          },
          onFilter: (value, record) => record.status.indexOf(value) === 0,
        },
      ],
    };
  },
  methods: {
    formatFeeName,
    async fetchPayments(classId = "all") {
      this.tableLoading = true;
      await apiClient
        .get("/payment/etatImpaye/scolarite/" + classId)
        .then((res) => {
          let data = res.data.map((p) => ({
            ...p,
            status: getStatus(p.fees),
          }));
          this.rowData = data;

          this.activeData = this.rowData;
          this.filtredTable = this.activeData;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    async handleFilterChange(on, value) {
      const other = on == "classe" ? "month" : "classe";
      let newData = this.rowData;
      if (value == "all") this.filters[on] = false;
      else {
        this.filters[on] = true;
        this.filters[`${on}_val`] = value;
        if (on == "classe") {
          await this.fetchPayments(value);
          newData = this.rowData;
        } else {
          newData = [];
          newData = this.rowData
            .map((elem) => {
              const fees = [...elem.fees];
              let newFees = this.filterFees(elem, fees, value);
              return {
                ...elem,
                fees: newFees,
                status: getStatus(newFees),
              };
            })
            .filter((e) => e.fees.length > 0);
        }
      }

      //handle the other filter if exists (again)
      if (this.filters[other] && other == "month") {
        const temp = [...newData];
        newData = temp.map((elem) => {
          const fees = [...elem.fees];
          let newFees = this.filterFees(
            elem,
            fees,
            this.filters[`${other}_val`]
          );
          return {
            ...elem,
            fees: newFees,
            status: getStatus(newFees),
          };
        });
      }
      this.activeData = newData;
      this.filtredTable = newData;
    },
    filterFees(elem, fees, value) {
      return fees.filter((fee) => {
        if (fee.feeName == "inscription") return false;
        if (elem.mode == "M") {
          if (parseInt(fee.feeName.split("-")[1]) == parseInt(value))
            return true;
        } else {
          //mode is par tranche
          let trancheMonths = elem.tranchesFee.find(
            (t) => t.trancheNumber == fee.feeName
          ).months;
          if (trancheMonths.includes(parseInt(value))) return true;
        }
      });
    },
    convert() {
      this.$gtag.event("Imp tab etatPaiementScolarite", {
        event_category: "Impression PDF",
        event_label: "scolarite - etatPaiement section",
        value: 1,
      });

      var SelectedMonth = document.getElementById("selectMonth").innerText;
      var SelectedClass = document.getElementById("selectClass").innerText;
      let title = this.filters.month
        ? `Etat des paiements pour le mois ${SelectedMonth}`
        : `Etat des paiements pour tous les mois`;
      let subTitle = this.filters.classe
        ? "Etat des paiements - " + SelectedClass
        : "Etat des paiements - Scolartié";
      const doc = pdfHeader(this.settings, null, title, subTitle);
      var arr = [];
      const array = [];
      var counter = 1;

      for (let paymentRow of this.filtredTable) {
        let colSpan = 0;

        if (paymentRow.fees) {
          colSpan += paymentRow.fees.length;
        }
        arr.push({
          rowSpan: colSpan,
          content: counter,
          styles: { valign: "middle" },
        });
        arr.push({
          rowSpan: colSpan,
          content: paymentRow.student,
          styles: { valign: "middle" },
        });
        arr.push({
          rowSpan: colSpan,
          content: paymentRow.name,
          styles: { valign: "middle" },
        });
        arr.push({
          rowSpan: colSpan,
          content: paymentRow.parent,
          styles: { valign: "middle" },
        });
        counter++;

        for (let fee of paymentRow.fees) {
          let feeName = formatFeeName(fee.feeName);
          arr.push(feeName);
          fee.isPayed
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,

        head: [["Num", "Nom complet", "Classe", "Parent", "Frais", "Etat"]],
        body: array,
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat_paiements_scolarité.pdf");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style scoped></style>
